<template>
  <div class="container-fluid py-2 py-md-3 py-lg-5 project-item-container" :class="'project-item--'+index">
    <div class="row project-item" v-bind:class="{ featureleft : isLeft, featureright : isRight }">
      <div class="col-12 col-lg-6 left px-0 py-3" data-aos="fade-right">
        <div class="featured-img">
          <img v-lazy="imgOne" alt class />
        </div>
        <div v-if="index % 2 === 0" class="copy pt-4">
          <h3 class="pt-2 py-lg-3 mb-0">{{title}}</h3>
           <router-link :to="'/project/'+id" class="cta text-uppercase" v-if="isLeft">
           See more
              <b-icon icon="arrow-right-short"></b-icon>
            </router-link>
           
        </div>
      </div>
      <div class="col-12 col-lg-6 right px-0 py-3" data-aos="fade-left">
        <div class="featured-img">
          <img v-lazy="imgTwo" alt class />
        </div>

        <div v-if="index % 2 !== 0" class="copy pt-4">
          <h3 class="pt-2 py-lg-3 mb-0">{{title}}</h3>
          <router-link :to="'/project/'+id" class="cta text-uppercase" v-if="isRight">
           See more
              <b-icon icon="arrow-right-short"></b-icon>
            </router-link>
        </div>
      </div>
    </div>

    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    title: String,
    id: Number,
    imgOne: String,
    imgTwo: String
  },
  data(){
      return{
          isLeft: false,
          isRight: false,
      }
  },
  methods:{
    featureSide(){
        if(this.index % 2 == 0){
            this.isLeft = true;
        }else{
             this.isRight = true;
        }
    }
  },
  mounted() {
      this.featureSide();
  }
};
</script>

<style>
</style>