<template>
  <div class="LayoutDefault">
    <TheHeader/>
    <TheNav/>
    <main class="LayoutDefault__main">
      <slot/>
    </main>
    <TheFooter/>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheNav from '@/components/TheNav.vue'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components:{
    TheHeader, TheNav, TheFooter
  }
}
</script>