<template>
  <layout-default >
    <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
<div v-if="itLoads" class="layout__projects position-relative overflow-hidden">
      <div id="D" class="letter">
        <img src="@/assets/img/texture1.jpg" class="letter__img" :style="{ 'mask-image':maskimgD }" />
      </div>
      <div class="container-fluid position-relative z-index-5 pb-5 mb-4">
        <div class="row">
          <div class="col-12 offset-lg-1 col-lg-5 mt-10">
            <h1 class="text-white">{{this.pageData.title.rendered}}</h1>
            <transition name="fade" v-show="itLoads">
              <div class="w-100 py-5 tx-c-tertiary" v-if="pageData.content.rendered" v-html="pageData.content.rendered">
              </div>
          </transition>
          </div>
        </div>
      </div>
      <template v-if="itLoadsProjects">
        <project-list-item
          v-for="(item, index) in items"
          :key="index"
          :index="index"
          :title="item.title.rendered"
          :id="item.id"
          :imgOne="item.acf.cover_image_1"
          :imgTwo="item.acf.cover_image_2"
        ></project-list-item>
      </template>
    </div>
    </section>
    
  </layout-default>
</template>

<script>
import imgMaskD from "@/assets/img/d.svg";
import ProjectListItem from "@/components/projects/projectsListItem";
import LayoutDefault from "@/layouts/LayoutDefault";
export default {
  inject: ["MyLoading"],
  layout: "template-white",
  components: {
    ProjectListItem,
    LayoutDefault,
  },
  data() {
    return {
      maskimgD: "url(" + imgMaskD + ")",
      items: [],
      basicData: [],
      pageData: Object,
      itLoads: false,
      itLoadsProjects: false,
      dataLoading: true,
      dataErrored: false,
    };
  },
  head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.pageData.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });

      return {
        title: this.dataLoading ? 'RBD' : this.pageData._yoast_wpseo_title,
        meta: metaArray,
      }
    }
  },
  methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/13")
        .then((response) => {
          this.pageData = response.data;
          this.itLoads = true;
          this.MyLoading.val = false;
        })
        .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    },
    fetchingProjects() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/project/")
        .then((response) => {
          this.items = response.data;
          this.basicData = this.items.map((project) => {
            return {
              id: project.id,
              title: project.title.rendered,
            };
          });
          this.itLoadsProjects = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getItem(theArray, i) {
      return theArray.indexOf(i);
    },
  },
  mounted() {
    this.fetchingData();
    this.fetchingProjects();
  },
  created() {
    this.MyLoading.val = true;
  },
};
</script>

<style>
</style>